.rotate-180 {
    transform: rotate(180deg);
}

.stepper-indicator {
    overflow-x: scroll;
    scroll-behavior: smooth;
    width: 50%;
}

.stepper-indicator::-webkit-scrollbar {
    border-radius: 10px;
    height: 1px;
}

.stepper-indicator::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 1px;
}