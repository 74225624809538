.table-head {
    background: #002E5D;
    color: #fff;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    height: 42px;
}

.table-job-title {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.table-job-title-caption {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.table-tr-default {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}

.table-archive-alert {
    border-radius: 6px;
    background: rgba(235, 87, 87, 0.5);
    height: 46px;
}

.table-archive-btn-yes {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    color: #fff;
    width: 58px;
    background: transparent;
}

.table-archive-btn-no {
    background: #EB5757;
    border-radius: 2px;
    color: #fff;
    width: 58px;
}
.breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, ">") !important;
}

.breadcrumb-text {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.no-jobs-container{
    /* Rectangle 592 */
    box-sizing: border-box;
    /* width: 863px; */
    height: 380px;

    background: rgba(255, 255, 255, 0.3);
    /* Body */

    border: 2px dashed #27323C;
    border-radius: 5px;
}

.no-jobs-img{
    width: 225.57px;
    height: 160px;
}

.skills-in-box{
    font-size: 12px;
    background-color: #002E5D;
    margin-right: 5px;
    /* height: 25px; */
    margin-bottom: 5px;
    border-radius: 5px;
    
    height: fit-content;
}
.skills-input{
    border:0;
    outline:0;
}
.skills-input:focus {outline:none!important;}

.fwb{
    font-weight: 600;
}

.form-control, .select-search__input{
    padding-left: 0.75rem!important;
}