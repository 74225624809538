.profile-backdrop{
    /* display: none; */
    position: fixed;
    top:0;
    bottom:0;
    left:0%;
    right:0;
    background-color:#0000004D;
    z-index:9999;
}
.profile-card-main{
    /* display: none; */
    position: fixed;
    top:0;
    bottom:0;
    left:20%;
    right:0;
    width: 80%;
    background-color:white;
    z-index:10000;
    overflow:scroll;
    padding-bottom:40px;
}

.profile-card-footer{
    position: fixed;
    bottom:0;
    right:0;
    left:20%;
    height:70px;
    background-color:#ffffff;
    z-index: 1002;
    box-shadow: 0px 0px 10px 1px #00000024;
}

@media screen and (max-width: 640px){
    .profile-card-main{
        left: 0;
        width: 100%;
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 770px){
    .profile-card-footer, .application-status-footer{
        height: auto!important;
        left: 0;
        padding: 12px 0;
    }
    
}


  