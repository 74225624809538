.bg-pricing{
    background: url("../../assets/img/pricing-page/bg-pricing.png");
    background-repeat: no-repeat;
    min-height: 90vh;
    background-size: cover;
}

.prices-paper{
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.feature-container{
    background: #F4F5F5;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;

}

.border-gold{
    border: 1px solid #D7A048;
}

.price-button{
    width: 189.8px;
    /* grayscale/gray 10% */
    background: #E9EBEC;
    /* grayscale/gray 25% */
    border: 1px solid #C9CCCE;
    border-radius: 10px;
    text-align: center;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.price-button-active{
    background: #002E5D !important;
    color: #fff !important;
}

.font-accent-color{
    color:#D7A048
}

.btn-typo{
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;

}

.premium-details-container{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.price-badge{
    font-size: 10px;
    padding: 3px;
    color: #fff;
    border-radius: 20px;
    top:-12px;
    position: absolute;
    right: 5px;;
    padding-left: 5px;
    padding-right: 5px;
}

.p-badge-gold{
background: #D7A048;
}

.p-badge-blue{
background: #009CDE;
}