.profile-wizard-bg{
    background: url('../../assets/img/profileWizardBg.png');
    background-position: center center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
}

.scale-out-left {
	-webkit-animation: scale-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: scale-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

 @-webkit-keyframes scale-out-left {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 1;
    }
  }
  @keyframes scale-out-left {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 1;
    }
  }
  