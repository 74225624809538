.disable-select {
    user-select: none;
    -webkit-touch-callout: none;
  }
  
input:focus,
textarea:focus {
    outline: none !important;
    box-shadow: none !important;
}
input {
    padding-left: 0 !important;
}
.btn-check:checked + .btn-outline-primary {
    background-color: #002e5d;
    border-color: #002e5d;
    box-shadow: none;
}
.btn-check:hover + .btn-outline-primary {
    background-color: #002e5d;
    border-color: #002e5d;
}
.btn-outline-primary {
    color: #002e5d;
    border-color: #002e5d;
    text-align: left;
}
.edit-profile-bg {
    background: url('../../assets/img/createJob/createJobBg.png');
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    overflow-y: auto;
    margin-top: -17px;
    padding-top: 60px;
    padding-bottom: 100px;
    height: 94vh;
    margin-bottom: -50px;
}

.job-card-bg{
    background-image: url('../../assets/img//editProfile/bgPattern.png');
    background-size: auto;
    background-position: center center;
    background-repeat: no-repeat;
}