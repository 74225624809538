.btn.btn-outline-warning {
    border: 2px solid #d7a048 !important;
}
.btn.btn-outline-warning:hover {
    background-color: #d7a048 !important;
}
.text-warning {
    color: #d7a048 !important;
}

.btn:hover > .text-dark {
    color: #000000 !important;

}

.btn-outline-danger:hover {
    background-color: transparent !important;
    color: #dc3545!important;
}
