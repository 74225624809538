.notif-content {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.notif-bar {
    background: #fff;
}

.notif-bar:hover {
    box-shadow: 2px 1px 10px 1px #a3a2a2;
}