input:focus, textarea:focus{
    outline: none!important;
    box-shadow: none!important;
}
input{
    padding-left: 0!important;
}
.btn-check:checked+.btn-outline-primary{
    background-color: #002E5D;
    border-color: #002E5D;
    box-shadow: none;
}
.btn-check:hover+.btn-outline-primary{
    background-color: #002E5D;
    border-color: #002E5D;
}
.btn-outline-primary {
    color: #002E5D;
    border-color: #002E5D;
    text-align: left;
}
/* .edit-profile-bg{
    background: url('../../assets/img/editProfileBg.png');
    background-position: center center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
} */
.btn-job-fair{
    border: 1px solid #ffffff;
    background-color: transparent;
    color: #ffffff;
}
.btn-job-fair:hover{
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #ffffff;
}
.w-120{
    width: 120px;
}

