.card-applicant-name {
    font-family: 'Poppins';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #003D59;
}

.floating-fab {
    background: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 5px;
    border: 2px solid #f2f2f2;
}
.fab-label {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.close-full-view {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}
.verified-badge {
    height: 21.333332061767578px;
    width: 24px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    margin-left: 10px;
}

.card-applicant-description {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.card-applicant-details {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.card-applicaant-other-details {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
    padding: 0px;
}

.blue-chip {
    background: #002E5D;
    border-radius: 16px;
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    margin-right: 3px;
    margin-bottom: 2px;
}

.see-more {
    position: relative;
    width: 105px;
    height: 105px;
}

.see-more-bg {
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.see-more-content {
    position: relative;
}

.min-100 {
    min-width: 70px;
}

.action-label {
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #27323C;
}

.explore-action-container {
    background: #FFFFFF;
    border: 1px solid rgba(128, 128, 128, 0.3);
    box-sizing: border-box;
    border-radius: 65px;
    width: 317px;
    left: 0px;
    top: 0px;
    border-radius: 65px;
}

.carousel p {
    text-align: left;
}
.expanded-card-title {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #27323C;
}
.no-more-candidates {
    width: 98%;
    height: 380px;
    background: rgba(255, 255, 255, 0.3);
    border: 2px dashed #27323C;
    box-sizing: border-box;
    border-radius: 5px;
    top: 25px;
}

.no-more-candidate-img {
    height: 200px;
    width: 152.67857360839844px;
    border-radius: 0px;
    
}

.bluured-overflow{
    background-image: linear-gradient(rgba(255,255,255, 0), rgba(189, 189, 189,0.1));
    min-height: 10px;
    /* backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px); */
}

.round{
    border-radius: 50%;
    border: '2px solid red';
}

.lets-make-your-first-job{
    box-sizing: border-box;
    
    position: absolute;
    width: 960px;
    height: 390px;
    left: 240px;
    top: 80px;
    
    background: rgba(255, 255, 255, 0.3);
    /* Body */
    
    border: 2px dashed #27323C;
    border-radius: 5px;
}

.first-job-container{
    width: 100vw;
    height: 117vh;
    top:150px;
    position: absolute;
    backdrop-filter: blur(5px);
    background: rgba(128, 128, 128, 0.3);
}

.dot{
    width: 7px;
    height: 7px;
    background: #27323C;
    border-radius: 50%;
    margin-left: 1px;
    margin-right: 1px;
}

.dotsDiv{
    height: 55px;
    background-image: linear-gradient(#FFFFFF0d, #FFFFFF, #FFFFFF);
}

.dotsParentDiv{
    position:absolute;left:0;bottom:0;right:0;z-index:9;
}

.cpointer{
    cursor: pointer;
}

.bg-primary2{
    background-color: #002E5DB3;
}

.w-200{
    width:200px!important;
}

.action-animate{transition: transform .2s;}
.action-animate:hover{transform: scale(1.5)!important;}
.action-animate-text:hover + .action-animate{transform: scale(1.5)!important;}


.action-animate:hover:active{transform: scale(1)!important;}
.action-animate-text:hover:active + .action-animate{transform: scale(1)!important;}


.modal-backdrop, .modal {
    z-index: 10001!important;
}
.floating-action-button{
    border-radius: 50%;
    position: fixed;
    bottom: 30px;
    right: 10px;
    width: 80px;
    height: 80px;
    z-index: 3000;
}

.skills-pill{
    padding: 3px 10px;
    font-size: 12px;
    border-radius: 32px;
    color: #ffffff;
    background-color: #009CDE;
}