.cover-photo {
    height: 140px;
    width: 764px;
    left: 141px;
    top: 110px;
    border-radius: 5px;
    object-fit: cover;
    background-size: contain;
    background-repeat: no-repeat;
}

.overlapped-div {
    position: relative;
    top: 70%;
}

.video-player-container {
    width: 240px;
}

.profile-banner{
    background-image: url('../../assets//img/profile/profileBg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.empProf .react-thumbnail-generator img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.editProf .react-thumbnail-generator img{
    width: 100%!important;
    height: 100%!important;
    object-fit: cover!important;
}

.compShowcase .react-thumbnail-generator{
    height: 100%!important;
    object-fit: 'cover';
}

.compShowcase .react-thumbnail-generator img{
    width: 100%!important;
    height: 100%!important;
    object-fit: cover!important;
}

.react-thumbnail-generator video {
    width: 150px!important;
    height: 100px!important;
}

.react-thumbnail-generator canvas {
    display: none;
}


.bg-primary-blue{
    background-color: #002e5d !important;

}

.profile-icon{
    filter: drop-shadow(0px 30px 30px rgba(0, 156, 222, 0.25))
}

