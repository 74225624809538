.sent-by-me {
    /* Accent Color 02 (25%) */
    background: #CCEBF8;
    /* drop shadow */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

}

.not-me {
    /* White */

    background: #FFFFFF;
    /* Border color */

    border: 1px solid rgba(128, 128, 128, 0.3);
    box-sizing: border-box;
    /* drop shadow */

    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.convo {
    max-width: 80%;
    padding: 10px;
    margin-bottom: 10px;
}


.info-container{
    width: 0px;
    opacity: 0;
    padding: 0;
    display: none;
    -webkit-transition: width, display 200ms ease-in-out;
    -moz-transition: width, display 200ms ease-in-out;
    -o-transition: width, display 200ms ease-in-out;
    transition: width, display 200ms ease-in;

}

.contact-info-expanded{

    /* width: 280px;    */
    opacity: 1;
    display: block;
    padding: 10px;
}

.contact-profile-img{
    width: 0px;
    height: 0px;
    border-radius: 5px;
    transition: height 500ms ease-in-out;
}
.contact-profile-img-expand{
    width: 100px;
    height: 100px;
}

.convo-container{
    min-height: 750px;
    max-height: 750px;
    overflow-y: auto;
}

.unread {
    background-color: #F4F5F5!important;
}