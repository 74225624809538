.ad-container-small {
    width: 300px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
    overflow: hidden; /* Hide overflow */
    display: flex;
    justify-content: center; /* Center ad if smaller than container */
    align-items: center; /* Center vertically */
  }
  

  .ad-container-medium {
    width: 468px; /* Adjust width as needed */
    height: 60px; /* Adjust height as needed */
    overflow: hidden; /* Hide overflow */
    display: flex;
    justify-content: center; /* Center ad if smaller than container */
    align-items: center; /* Center vertically */
  }
  .ad-container-medium-mod {
    width: 420px; /* Adjust width as needed */
    height: 60px; /* Adjust height as needed */
    overflow: hidden; /* Hide overflow */
    display: flex;
    justify-content: center; /* Center ad if smaller than container */
    align-items: center; /* Center vertically */
  }
  

  .ad-container-large {
    width: 728px; /* Adjust width as needed */
    height: 90px; /* Adjust height as needed */
    overflow: hidden; /* Hide overflow */
    display: flex;
    justify-content: center; /* Center ad if smaller than container */
    align-items: center; /* Center vertically */
  }

  .ad-container-square {
    width: 300px; /* Adjust width as needed */
    height: 250px; /* Adjust height as needed */
    overflow: hidden; /* Hide overflow */
    display: flex;
    justify-content: center; /* Center ad if smaller than container */
    align-items: center; /* Center vertically */
  }
  